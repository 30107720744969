@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /* justify-content: space-between; */
  }
}

.name {
  gap: 40px;

  @media(min-width:768px) {
    display: flex;
    justify-content: space-between;

  }

  /* margin-top: 24px; */

  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
}

.firstNameRoot {
  @media(min-width:768px) {
    width: 50%;
    flex: 50%;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000;
  }
}

.lastNameRoot {
  @media(max-width:991px) {
    width: 50%;
    flex: 0 0 50%;
  }

  @media(max-width:767px) {
    margin-top: 12px;
    width: 100%;
    flex: 0 0 100%;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 12px;

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }
}

.email {
  composes: marketplaceModalPasswordMargins from global;
  margin-top: 12px;

  &>input {
    background-color: #F9F9F9;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    font-family: var(--fontFamilyHelvetica);
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #000;
    height: 48px;
    margin-top: 8px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #BDBDBD;
    }
  }

  &>label {
    font-family: var(--fontFamilyFactor);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  & button {
    /* background-color: #F9F9F9 !important;
    border-radius: 10px;
    color: #000;
    font-weight: 500;
    font-family: var(--fontFamilyFactor);
    font-size: 14px;
    line-height: 16px;

    &:hover,
    active {
      background-color: #fff !important;
      color: #000 !important;
    }

    &>svg {
      stroke: #000 !important;
    } */
  }
}

.passwordRule {
  margin-top: 15px;

  & p,
  li {
    font-family: 'Helvetica Neue';
    font-weight: 400;
    font-size: 10px;
    padding: 0px !important;
    margin: 0px !important;
    color: #676767;
    line-height: 13px;
    /* list-style-type: disc; */
  }

  & li {
    position: relative;
    padding-left: 10px !important;

    &::after {
      position: absolute;
      content: "";
      top: 4px;
      left: 0;
      background-color: #676767;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ul {
    margin: 0px;
    margin-left: 15px;
    margin-top: 15px;
  }

  li {
    list-style-type: disc !important;
  }
}

.errorMessage {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
  color: var(--colorFail);
  line-height: 13px;
}

.enableButton{
  background-color:#000 !important;
  color:#fff !important;
  border-radius: 10px;
  &>svg {
    stroke: #000 !important;
  }
}
.disabledButton{
  background-color: #F9F9F9 !important;
  border-radius: 10px;
  color: #000;

  /* &:hover,
  active {
    background-color: #fff !important;
    color: #000 !important;
  } */

  &>svg {
    stroke: #000 !important;
  }
}